import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—December 2008" />
	<Old2005BlogPage>
		<h2>December 2008</h2>



		<h3 className="blogdate">
		Sunday, December 07, 2008
		</h3>






		<h4 className="blogitemtitle">
		Strange disappointments in the shared kitchen
		</h4>





		   <div className="blogitembody">
		   (<span style={{fontStyle: "italic"}}>what I'm dealing with here</span>)<br /><br />The dish rag had transformed<br /> once yellow and crisp<br />     square<br /> now brown and stretched<br />someone had been dragging it through<br /> greasepools<br />     perhaps even the cast iron pan<br /> not washing it with hot soapy water<br />so I disposed of it<br /> in the garbage<br />     gone<br />and lo...<br /> I returned home in the evening<br />     there it was<br /> resurrected and hanging<br />at the sink<br />ready for action.
		   </div>
		   <div className="blogitemfooter">
		   posted by Jesse @ 12:25 AM &nbsp;
		   </div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
